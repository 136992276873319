<template>
  <div id="print-page">
    <div class="d-flex justify-content-end">
      <b-button
        @click="PrintElem"
        v-if="eventDoctor && !eventDoctor.formPrinted"
        class="btn-success"
        >{{ $t("PRINT_FORM.PRINT") }}</b-button
      >
      <b-button
        @click="goToNextPage"
        v-if="eventDoctor && eventDoctor.formPrinted"
        class="btn-primary ml-2"
        >{{ goForward }}</b-button
      >
    </div>

    <div id="print-form" v-if="doctor" class="mt-2">
      <div class="d-none d-print-flex justify-content-end">
        {{ formatDate() }}
      </div>
      <div class="contact-form mt-5">
        <h2 class="text-center">{{ $t("PRINT_FORM.CONTACT_DETAILS") }}</h2>
        <div class="contact-details pl-5">
          <p>
            {{ $t("FIELDS.NAME") }}:<span>{{ doctor.name }}</span>
          </p>
          <p>
            {{ $t("FIELDS.EMAIL") }}:<span>{{ doctor.email }}</span>
          </p>
          <p>
            {{ $t("FIELDS.MOBILE_NUMBER") }}:<span>{{ doctor.mobileNo }}</span>
          </p>
          <p>
            {{ $t("FIELDS.SPECIALITY") }}:<span>{{
              getSpeciality(doctor.speciality)
            }}</span>
          </p>
          <p>
            {{ $t("FIELDS.GOVERNORATE") }}:<span>{{
              getGovernorate(doctor.governorate)
            }}</span>
          </p>
          <p>
            {{ $t("FIELDS.ADDRESS") }}:<span>{{ doctor.address }}</span>
          </p>
          <p>
            {{ $t("FIELDS.BEEN_VISITED") }}:<span>{{
              getInvited(doctor.invited)
            }}</span>
          </p>
        </div>
      </div>
      <div class="acceptance d-flex">
        <div class="left-side w-50 border border-dark border-right-0 p-2">
          <p>
            I accept to receive communication from NN Egypt around events,
            medical education, our products and services. The communication may
            be via phone, email, letter, text message, etc. Some messages may be
            promotional in nature.
            <strong>You may unsubscribe at any time.</strong>
          </p>
          <p>
            <strong><u>Notice on data processing</u></strong> As part of the
            services, Novo Nordisk process your personal data (PD) for the
            purposes of managing the relationship with you and to optimize
            services, as it is necessary for our legitimate interests, to ensure
            compliance with legal requirements, and to fulfil contractual
            obligations. You may at any time request an overview of all PD
            collected and processed and request correction and/or deletion of
            your PD. You may exercise these rights, including requesting us to
            stop or limit the processing of your PD by contacting
            <a class="text-primary">EGPRIVACY@jarvis.com</a> .
          </p>
          <p class="terms">
            I, the undersigned hereby agree to the above-mentioned terms, and
            accept Novo Nordisk to keep and process my personal data in
            accordance to its T&C.
          </p>
          <p class="signature">Name:</p>
          <p class="signature">Signature:</p>
        </div>
        <div class="right-side w-50 border border-dark p-2">
          <p>
            أوافق أنا الموقع أدناه على استقبال المخاطبات من شركة نوفونورديسك
            فيما يتعلق بالمؤتمرات والفاعليات الطبية والمتعلقة بمنتجات الشركة
            وخدماتها، ويمكن أن يتم التواصل من خلال الهاتف أو البريد الاليكتروني
            أو الخطابات أو الرسائل النصية وخلافه، ويجوز أن تكون هذه الرسائل ذات
            طبيعة دعائية.<strong>
              يحق لسيادتكم الغاء استقبال تلك المخاطبات في أي وقت</strong
            >.
          </p>
          <p>
            <strong>معالجة البيانات الشخصية</strong>: تقوم الشركة بحفظ ومعالجة
            بياناتكم الشخصية لغرض توفير وتسهيل التواصل مع سيادتكم، للأغراض
            المشروعة قانوناً وللالتزام بالقوانين والالتزامات العقدية. يحق
            لسيادتكم في أي وقت طلب مراجعة جميع بياناتكم الشخصية التي تم جمعها
            ومعالجتها، وطلب تصحيحها أو مسحها. يحق لكم ممارسة هذا الحق بما في ذلك
            طلب وقف أو الحد من معالجة بياناتكم عن طريق التواصل معنا من خلال
            البريد الاليكتروني الاتي:
            <a class="text-primary">EGPRIVACY@jarvis.com</a>
          </p>
          <p class="terms">
            أوافق أنا الموقع أدناه على جميع ما ورد بعالية، وأوافق على أن تقوم
            شركة نوفونورديسك بحفظ ومعالجة بياناتي وفقا لسياسة الخصوصية الخاصة
            بها.
          </p>
          <p class="signature">:الاسم</p>
          <p class="signature">:التوقيع</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as $ from "jquery";
import constants from "@/common/config/constants.json";
import html2pdf from "html2pdf.js";
import domtoimage from "dom-to-image";
// import electronVuePrinter from "electron-vue-printer";

export default {
  created() {
    this.getDoctor({ id: this.$route.params.id });
  },
  mounted() {
    // var afterPrint = function() {
    //   console.log("Functionality to run after printing");
    // };
    // window.onafterprint = afterPrint;
    this.storage();
  },
  components: {
    // electronVuePrinter,
  },
  data() {
    return {
      formFile: null,
      fileString: null,
    };
  },
  methods: {
    ...mapActions({
      getDoctor: "getDoctor",
      printForm: "printForm",
      printImage: "printImage",
    }),
    storage() {
      localStorage.getItem("language");
    },
    PrintElem() {
      let that = this;

      return new Promise(async (resolve, reject) => {
        let that = this;

        try {
          const blobImage = await domtoimage.toBlob(
            document.getElementById("print-form")
          );

          const formData = new FormData();
          const fileImage = new File([blobImage], "event-photo", {
            type: "image/png",
          });

          formData.append("image", fileImage);

          that.printForm({
            eventId: that.$route.params.eventId,
            doctorId: that.$route.params.id,
            formFile: formData,
          });
        } catch (e) {
          reject(e);
        }
      });
    },
    formatDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;

      return formattedToday;
    },
    goToNextPage() {
      if (
        this.event.hasQuestionnaire &&
        this.eventDoctor &&
        !this.eventDoctor.questionnaireSubmitted
      ) {
        this.$router.push({
          name: "Questionnaire",
          params: { eventId: this.event.id, doctorId: this.doctor.id },
        });
      } else if (
        this.event.hasPhoto &&
        this.eventDoctor &&
        !this.eventDoctor.photoPrinted
      ) {
        this.$router.push({
          name: "Select Frame",
          params: {
            doctorId: this.doctor.id,
            eventId: this.event.id,
          },
        });
      } else if (
        this.event.hasVoucher &&
        this.eventDoctor &&
        !this.eventDoctor.voucherPrinted
      ) {
        this.$bvModal.show("voucher-modal");
      } else {
        this.$router.push({ name: "Thank You" });
      }
    },
    getSpeciality(id) {
      const spec = this.specialities.find(
        (speciality) => speciality.value === id
      );

      if (localStorage.getItem("language") === "en") {
        return spec.TextEn;
      } else {
        return spec.TextAr;
      }
    },
    getGovernorate(id) {
      const gov = this.governorates.find(
        (governorate) => governorate.value === id
      );

      if (localStorage.getItem("language") === "en") {
        return gov.governorate_name_en;
      } else {
        return gov.governorate_name_ar;
      }
    },
    getInvited(ans) {
      if (ans) {
        if (localStorage.getItem("language") === "en") {
          return "Yes";
        } else {
          return "نعم";
        }
      } else {
        if (localStorage.getItem("language") === "en") {
          return "No";
        } else {
          return "لا";
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      doctor: "doctor",
      event: "event",
      eventDoctor: "eventDoctor",
    }),
    specialities: () => {
      return constants.specialities;
    },
    governorates() {
      return constants.governorates;
    },
    goForward() {
      if (
        this.event.hasQuestionnaire &&
        this.eventDoctor &&
        !this.eventDoctor.questionnaireSubmitted
      ) {
        return this.$t("PRINT_FORM.GO_TO_QUESTIONNAIRE");
      } else if (
        this.event.hasPhoto &&
        this.eventDoctor &&
        !this.eventDoctor.photoPrinted
      ) {
        return this.$t("EVENT.EVENT_DETAILS.TAKE_PHOTO");
      } else if (
        this.event.hasVoucher &&
        this.eventDoctor &&
        !this.eventDoctor.voucherPrinted
      ) {
        return this.$t("EVENT.EVENT_DETAILS.RECIEVE_VOUCHER");
      } else {
        return this.$t("COMMON.THANK_YOU");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-success,
.btn-primary {
  width: 200px;
}

.acceptance {
  direction: ltr !important;
}

@media print {
  h2 {
    color: red;
  }
}
</style>
